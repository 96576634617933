<template>
  <div :class="[mobileView ? 'contact-me-m' : 'contact-me']">
    <text :class="[mobileView ? 'cm-text-m' : 'cm-text']"> Oh you're still here? </text>
    <text :class="[mobileView ? 'cm-text-m' : 'cm-text']"> Let's connect!</text>
    <div :class="[mobileView ? 'button-li-m' : 'button-li']">
        <a href="mailto:rissaartt@gmail.com">
            <img :class="[mobileView ? 'btn-m' : 'contact-btn-1']" src="@/assets/email-btn.svg"/>
        </a>
        <a href="https://www.linkedin.com/in/arissarashid/" target="_blank">
            <img :class="[mobileView ? 'btn-m' : 'contact-btn']" src="@/assets/linkedin-btn.svg"/>
        </a>
        <a href="https://www.instagram.com/rissaartt/" target="_blank">
            <img :class="[mobileView ? 'btn-m' : 'contact-btn']" src="@/assets/ig-btn.svg"/>
        </a>
        <a href="https://www.dribbble.com/arissa/" target="_blank">
            <img :class="[mobileView ? 'btn-m' : 'contact-btn']" src="@/assets/dribbble-btn.svg"/>
        </a>
    </div>
    <svg v-if="!mobileView" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1366 139.4" style="enable-background:new 0 0 1366 139.4;" xml:space="preserve" @click="backToTop" class="graphic">
        <path class="st0" d="M738,136.9c8.2,0,15.2-4.9,18.3-12h-36.6C722.8,131.9,729.8,136.9,738,136.9z"/>
        <path class="st0" d="M636,136.9c8.2,0,15.2-4.9,18.3-12h-36.6C620.8,131.9,627.8,136.9,636,136.9z"/>
        <g>
            <g>
                <g>
                    <g>
                        <path class="st1" d="M1167.3,125.6c-0.7-12.9,2.3-27.4,4.9-41.4c0,0,19.7-6.8,23.9-19.3c0,0,11,15.5,38,12.2
                            c0,0,13.3,16.3,31.8,13.3c0,0,6.5,17.3,6.2,35.3 M1335.4,125.6c0.9-8.5-2.4-23.4-32.7-30.8c-9.5-2.3-13.5-3.7-20.5-10.5
                            c-2-1.9-3.9-4.1-5.6-6.2c-8.5-10.4-19.5-28.5-42.8-32.7c-15.2-0.8-29.4,0-35.3,3.4c-1.9,0.6-3.9,1.2-5.8,2
                            c0,0-20.3-1.2-38.2,31.3s-39.2,13.9-51,38.2c-0.9,1.9-1.6,3.6-2.1,5.4 M1235.4,66.2c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4
                            s-5.4-2.4-5.4-5.4S1232.4,66.2,1235.4,66.2z M1182.7,68.3l-1.9,2.8L1182.7,68.3z M1174.5,69.3l-1.9,2.8L1174.5,69.3z"/>
                    </g>
                    <g>
                        <path class="st2" d="M1272,128.4L1272,128.4c-1.6,0-2.8-1.3-2.8-2.8c0.3-13.8-3.7-27.3-5.3-32.3c-15.9,1.4-27.6-9.7-31.1-13.4
                            c-2.2,0.2-4.4,0.3-6.4,0.3c-15.8,0-25.3-6.5-29.7-10.5c-5.6,9.2-17.9,14.8-22.3,16.5l-0.3,1.5c-2.4,12.8-4.9,26.1-4.3,37.8
                            c0.1,1.5-1.1,2.8-2.6,2.9c-1.5,0.1-2.8-1.1-2.9-2.6c-0.7-12.3,1.9-25.9,4.4-39.1l0.6-3c0.2-1,0.9-1.8,1.8-2.1
                            c0.2-0.1,18.5-6.5,22.2-17.6c0.3-1,1.2-1.7,2.2-1.8c1-0.2,2,0.3,2.6,1.1c0.4,0.5,8.9,12,29.5,11.5c-0.4-1-0.6-2-0.6-3.1
                            c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2c0,3-1.6,5.6-4.1,7.1c4.8,4.3,14.3,10.8,25.9,8.9c1.3-0.2,2.6,0.5,3,1.7
                            c0.3,0.7,6.7,18,6.4,36.4C1274.8,127.2,1273.5,128.4,1272,128.4z M1235.4,69c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7
                            s2.7-1.2,2.7-2.7S1236.9,69,1235.4,69z M1101.4,128.4c-0.3,0-0.6,0-0.8-0.1c-1.4-0.5-2.2-2-1.8-3.5c0.6-2,1.4-3.9,2.3-5.7
                            c6-12.5,14.7-14.7,23.1-17c9.3-2.5,18.8-5,27.8-21.3c16.9-30.8,36.3-32.7,40.1-32.7c1.8-0.7,3.5-1.2,5.2-1.8
                            c8.2-4.5,26.4-4.2,36.6-3.6c0.1,0,0.2,0,0.3,0c21.2,3.8,32.6,18.5,41,29.2c1.2,1.6,2.4,3.1,3.5,4.4c1.7,2.1,3.6,4.2,5.4,6
                            c6.5,6.4,10,7.6,19.2,9.8c32.6,7.9,35.8,24.5,34.8,33.8c-0.2,1.5-1.5,2.6-3,2.4s-2.6-1.5-2.4-3c1.8-17.3-18.4-24.9-30.6-27.8
                            c-9.7-2.3-14.4-4-21.8-11.2c-2-1.9-4-4.1-5.9-6.4c-1.1-1.4-2.3-2.9-3.6-4.5c-7.8-10-18.4-23.7-37.4-27.2
                            c-16.2-0.9-28.8,0.3-33.6,3.1c-0.2,0.1-0.3,0.2-0.5,0.2c-1.9,0.6-3.8,1.2-5.7,1.9c-0.4,0.1-0.8,0.2-1.1,0.2
                            c-0.2,0-18.9-0.5-35.6,29.9c-10.1,18.4-21.3,21.4-31.2,24c-8.1,2.2-14.6,3.9-19.5,14c-0.8,1.6-1.4,3.2-2,5
                            C1103.6,127.6,1102.6,128.4,1101.4,128.4z M1172.6,74.9c-0.5,0-1-0.1-1.5-0.5c-0.8-0.5-1.2-1.4-1.2-2.3c0-0.5,0.1-1,0.5-1.5
                            l1.9-2.8c0.8-1.3,2.5-1.6,3.8-0.8c0.8,0.5,1.2,1.4,1.2,2.3c0,0.5-0.1,1-0.5,1.5l-1.9,2.8C1174.4,74.4,1173.5,74.9,1172.6,74.9z
                            M1180.9,73.9c-0.5,0-1-0.1-1.5-0.5c-0.8-0.5-1.2-1.4-1.2-2.3c0-0.5,0.1-1,0.5-1.5l1.9-2.8c0.8-1.3,2.5-1.6,3.8-0.8
                            c0.8,0.5,1.2,1.4,1.2,2.3c0,0.5-0.1,1-0.5,1.5l-1.9,2.8C1182.6,73.5,1181.8,73.9,1180.9,73.9z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path class="st3" d="M1272,125.6c0.3-18.1-6.2-35.3-6.2-35.3c-18.6,3-31.8-13.3-31.8-13.3c-27,3.4-38-12.2-38-12.2
                            c-4.2,12.5-23.9,19.4-23.9,19.4c-2.6,14-5.6,28.5-4.9,41.4"/>
                    </g>
                    <g>
                        <path class="st4" d="M1167.3,128.4c-1.5,0-2.7-1.1-2.7-2.6c-0.7-12.3,1.9-25.9,4.4-39.1l0.6-3c0.2-1,0.9-1.8,1.8-2.1
                            c0.2-0.1,18.4-6.6,22.2-17.7c0.3-1,1.2-1.7,2.2-1.8c1-0.2,2,0.3,2.6,1.1c0.4,0.6,10.5,14.2,35.4,11c0.9-0.1,1.9,0.3,2.5,1
                            c0.1,0.1,12.5,15,29.3,12.3c1.3-0.2,2.6,0.5,3,1.7c0.3,0.7,6.7,18,6.4,36.4c0,1.5-1.3,2.7-2.7,2.7c0,0,0,0-0.1,0
                            c-1.5,0-2.7-1.3-2.7-2.8c0.3-13.8-3.7-27.3-5.3-32.3c-0.9,0.1-1.9,0.1-2.8,0.1c-14.5,0-25-10-28.3-13.5
                            c-2.3,0.2-4.5,0.4-6.5,0.4c-15.7,0-25.2-6.5-29.6-10.6c-5.6,9.2-17.9,14.8-22.2,16.5l-0.3,1.5c-2.4,12.8-4.9,26.1-4.3,37.8
                            c0.1,1.5-1.1,2.8-2.6,2.9C1167.4,128.4,1167.4,128.4,1167.3,128.4z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path class="st0" d="M1254.4,106.4c-7.6,0-13.8,6.2-13.8,13.8c0,1.9,0.4,3.8,1.2,5.5h25.2c0.8-1.7,1.2-3.6,1.2-5.5
                            C1268.1,112.5,1261.9,106.4,1254.4,106.4z M1250.2,120.8l1.3-3.2L1250.2,120.8z M1257.4,122l1.3-3.2L1257.4,122z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path class="st0" d="M1184.2,106.4c-7.6,0-13.8,6.2-13.8,13.8c0,1.9,0.4,3.8,1.2,5.5h25.2c0.8-1.7,1.2-3.6,1.2-5.5
                            C1198,112.5,1191.8,106.4,1184.2,106.4z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <circle class="st1" cx="1235.4" cy="71.6" r="5.4"/>
                    </g>
                    <g>
                        <path class="st4" d="M1235.4,79.8c-4.5,0-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2C1243.6,76.2,1239.9,79.8,1235.4,79.8
                            z M1235.4,69c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7S1236.9,69,1235.4,69z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <circle class="st5" cx="1242.1" cy="104.4" r="3.6"/>
                    </g>
                </g>
                <g>
                    <g>
                        <circle class="st5" cx="1194.7" cy="103" r="3.6"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path class="st5" d="M1219.7,115.4c-5.1,0-8.9-2.2-9.2-2.4c-1.3-0.8-1.7-2.5-1-3.8c0.8-1.3,2.5-1.7,3.8-1
                            c0.2,0.1,6.2,3.5,12.1,0c1.3-0.8,3-0.4,3.8,0.9s0.4,3-0.9,3.8C1225.3,114.8,1222.3,115.4,1219.7,115.4z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path class="st6" d="M1219.7,51.7c0,0-19.9,7.6-20.1-12.5C1199.6,39.2,1216.8,29.4,1219.7,51.7z"/>
                        </g>
                        <g>
                            <path class="st7" d="M1211.8,55.2c-2.8,0-5.8-0.5-8.4-2.3c-3.8-2.6-5.8-7.2-5.9-13.7c0-0.8,0.4-1.4,1-1.8
                                c0.3-0.2,8.1-4.5,14.8-1.1c4.6,2.3,7.4,7.4,8.3,15.2c0.1,0.9-0.4,1.8-1.3,2.2C1220.1,53.7,1216.3,55.2,1211.8,55.2z
                                M1201.7,40.5c0.3,4.3,1.6,7.4,4,9c3.4,2.3,8.6,1.6,11.6,0.8c-0.9-5.4-2.9-8.8-5.8-10.3C1208,38.2,1203.7,39.6,1201.7,40.5z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path class="st7" d="M1219.7,53.8c-0.7,0-1.4-0.4-1.8-1c-3-5-9.6-5.6-9.7-5.6c-1.1-0.1-2-1.1-1.9-2.2s1.1-2,2.2-1.9
                                c0.4,0,8.7,0.7,12.9,7.6c0.6,1,0.3,2.2-0.7,2.8C1220.4,53.7,1220,53.8,1219.7,53.8z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path class="st6" d="M1219.7,51.7c0,0,19.9,7.6,20.1-12.5C1239.7,39.2,1222.4,29.4,1219.7,51.7z"/>
                        </g>
                        <g>
                            <path class="st7" d="M1227.5,55.2c-4.5,0-8.4-1.5-8.6-1.6c-0.9-0.3-1.4-1.2-1.3-2.2c0.9-7.6,3.7-12.7,8.1-15.1
                                c6-3.2,12.9-0.1,14.9,0.9c-0.1-0.1-0.4-0.2-0.8-0.2c0.5,0,1.1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.5c-0.1,6.5-2,11.1-5.9,13.7
                                C1233.3,54.6,1230.3,55.2,1227.5,55.2z M1219.7,51.7L1219.7,51.7L1219.7,51.7z M1221.9,50.3c2.6,0.7,8,1.7,11.7-0.8
                                c2.4-1.6,3.8-4.7,4-9c-2.1-0.9-6.5-2.3-10-0.4C1224.8,41.6,1222.9,45,1221.9,50.3z M1239.7,37.1L1239.7,37.1L1239.7,37.1z
                                M1239.7,37.1L1239.7,37.1L1239.7,37.1z M1239.7,37.1L1239.7,37.1L1239.7,37.1z M1239.7,37.1L1239.7,37.1L1239.7,37.1z
                                M1239.7,37.1L1239.7,37.1L1239.7,37.1z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path class="st7" d="M1219.6,53.8c-0.4,0-0.9-0.1-1.2-0.4c-0.9-0.7-1.1-2-0.4-2.9c4.4-5.8,12.8-7.3,13.1-7.4
                                c1.1-0.2,2.2,0.6,2.4,1.7c0.2,1.1-0.6,2.2-1.7,2.4l0,0c-0.1,0-7.2,1.3-10.5,5.8C1220.8,53.5,1220.2,53.8,1219.6,53.8z"/>
                        </g>
                    </g>
                </g>
            </g>
            <g>
                <path class="st8" d="M1179.3,124c-0.8,0-1.6-0.5-1.9-1.3l-1.3-3.2c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.8,0.5-1.6,1.3-1.9
                    c1.1-0.4,2.3,0.1,2.7,1.1l1.3,3.2c0.1,0.3,0.2,0.5,0.2,0.8c0,0.8-0.5,1.6-1.3,1.9C1179.8,124,1179.6,124,1179.3,124z"/>
            </g>
            <g>
                <path class="st8" d="M1186,123.5c-0.8,0-1.6-0.5-1.9-1.3l-1.4-3.2c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.8,0.5-1.6,1.3-1.9
                    c1.1-0.4,2.3,0.1,2.7,1.1l1.3,3.2c0.1,0.3,0.2,0.5,0.2,0.8c0,0.8-0.5,1.6-1.3,1.9C1186.5,123.4,1186.2,123.5,1186,123.5z"/>
            </g>
            <g>
                <path class="st8" d="M1257.1,124c-0.3,0-0.5-0.1-0.8-0.2c-0.8-0.3-1.3-1.1-1.3-1.9c0-0.3,0.1-0.5,0.2-0.8l1.3-3.2
                    c0.4-1.1,1.6-1.6,2.7-1.1c0.8,0.3,1.3,1.1,1.3,1.9c0,0.3-0.1,0.5-0.2,0.8l-1.3,3.2C1258.6,123.6,1257.9,124,1257.1,124z"/>
            </g>
            <g>
                <path class="st8" d="M1250.4,123.4c-0.3,0-0.5-0.1-0.8-0.2c-0.8-0.3-1.3-1.1-1.3-1.9c0-0.3,0.1-0.5,0.2-0.8l1.3-3.2
                    c0.4-1.1,1.6-1.6,2.7-1.1c0.8,0.3,1.3,1.1,1.3,1.9c0,0.3-0.1,0.5-0.2,0.8l-1.3,3.2C1252,122.9,1251.2,123.4,1250.4,123.4z"/>
            </g>
        </g>
        <g id="hand">
            <path class="st3" d="M1309.1,133.1l-2.6-51.2c0,0-0.2-4.9-4.1-4.9c-3.8,0-3.2,5.3-3.2,5.3s1.1-5-3.4-5.4s-3.2,5.7-3.2,5.7
                l-2.1-21.6c0,0-0.1-4.7-4.2-4.5c-7,0.4-4.8,25.3-4.8,25.3l1.7,52.9L1309.1,133.1z"/>
            <path class="st4" d="M1283.3,137.6c-0.7,0-1.5-0.3-2-0.8c-0.6-0.5-1-1.3-1-2.1l-1.7-52.8c-1.8-19.4,2.4-25.3,3.3-26.3
                c1.2-1.4,2.7-2.1,4.3-2.2c1.9-0.1,3.6,0.5,4.9,1.7c2.1,2,2.4,4.8,2.4,5.6l1.2,13.1c0.4,0,0.8,0,1.3,0c1.3,0.1,2.3,0.5,3.1,1
                c0.9-0.6,2-0.9,3.2-0.9c4.6,0,6.9,4,7.1,7.8l2.6,51.2c0.1,1.6-1.2,3.1-2.8,3.1l-25.8,1.5C1283.4,137.6,1283.4,137.6,1283.3,137.6z
                M1286.6,59.4L1286.6,59.4c-1.8,1-3.1,10.8-2,22.1c0,0.1,0,0.1,0,0.2l1.6,49.8l19.7-1.2l-2.5-48.3c0,0-0.2-2.1-1.1-2.1l0,0l0,0
                c0,0.1-0.1,0.2-0.1,0.3c0.1,1.4-0.1,2.5-0.2,2.7c-0.3,1.4-1.6,2.4-3.1,2.3s-2.6-1.2-2.8-2.7c0-0.3-0.1-1.3,0-2.4
                c0-0.1,0-0.1-0.1-0.2c0-0.1-0.2-0.1-0.6-0.2l0,0c0,0.2-0.1,0.4-0.1,0.6l0.2,1.8c0.1,1.6-1,3-2.5,3.2c-1.6,0.2-3.1-0.8-3.4-2.3
                c-0.1-0.4-0.3-1.3-0.3-2.6l-1.8-19.4c0-0.1,0-0.1,0-0.2c0-0.4-0.2-1.2-0.5-1.5C1287,59.4,1286.8,59.4,1286.6,59.4z M1302.3,79.9
                L1302.3,79.9L1302.3,79.9z"/>
        </g>
        <rect y="124.9" class="st9" width="1366" height="14.5"/>
        <g>
            <path class="st3" d="M1132.7,123.9c0,0-0.2-7.6,11-7.6c10.3,0,12.1,8.9,12.1,8.9s1,6.9-3.9,6.9c-4.8,0-2.8-4.8-2.8-4.8
                s-1.1,4.1-4.8,4.8c-3.7,0.7-4.8-4.8-4.8-4.8s-0.1,3.2-2.8,4.1C1130.5,133.7,1132,124.3,1132.7,123.9z"/>
            <path class="st4" d="M1143.7,135c-1.7,0-3.1-0.7-4.2-1.8c-0.5,0.4-1.1,0.7-1.8,1c-4,1.4-6.6-0.5-7.6-2.7c-0.9-2-1.1-5.8-0.1-8.2
                c0.1-1.2,0.7-3.7,2.7-5.8c2.4-2.5,6.1-3.8,11-3.8c9.9,0,14,7.3,14.8,11.1c0,0.1,0,0.1,0,0.2c0.1,0.8,0.6,5.2-1.8,7.9
                c-1.2,1.4-2.8,2.1-4.8,2.1c-1.9,0-3.2-0.6-4.1-1.3c-0.8,0.6-1.8,1-3,1.2C1144.5,135,1144.1,135,1143.7,135z M1139.6,124.6
                c1.3,0,2.4,0.9,2.7,2.2c0.2,0.8,0.9,2.8,1.6,2.6c1.4-0.3,2.2-1.7,2.5-2.4c0.1-0.4,0.2-0.7,0.3-0.8c0.6-1.3,2.1-2,3.4-1.5
                c1.4,0.5,2.1,1.9,1.8,3.3c-0.1,0.3-0.2,0.5-0.3,0.8c0,0.2-0.1,0.4-0.1,0.5c0.1,0,0.2,0,0.4,0c0.4,0,0.5-0.1,0.6-0.2
                c0.6-0.6,0.6-2.5,0.5-3.5c-0.2-0.9-1.9-6.7-9.4-6.7c-7.8,0-8.2,4-8.3,4.8c0,0.6-0.2,1.1-0.5,1.6c-0.3,1-0.3,3,0.1,3.6
                c0.1,0,0.4,0,0.8-0.2c0.8-0.3,0.9-1.7,0.9-1.7c0.1-1.4,1.1-2.5,2.5-2.6C1139.4,124.6,1139.5,124.6,1139.6,124.6z M1134.1,126.3
                L1134.1,126.3L1134.1,126.3z"/>
        </g>
        <g>
            <g>
                <path d="M1180.7,22.6c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.7-0.5-0.9-0.8l0.2-0.4v1.4h-1.6v-11h1.6v6.2v-0.5c0.2-0.3,0.6-0.5,1-0.7
                    s0.9-0.3,1.4-0.3s1,0.1,1.4,0.4s0.8,0.6,1,1.1c0.3,0.5,0.4,1,0.4,1.7s-0.1,1.2-0.4,1.7s-0.7,0.9-1.1,1.1
                    C1181.8,22.4,1181.3,22.6,1180.7,22.6z M1180.4,21.2c0.3,0,0.7-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.2-0.6,0.2-1
                    s-0.1-0.7-0.2-1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.3-0.9-0.3s-0.5,0.1-0.8,0.2s-0.5,0.2-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.6
                    V20c0.1,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.3,0.6,0.4C1179.8,21.2,1180.1,21.2,1180.4,21.2z"/>
                <path d="M1187.9,22.6c-0.6,0-1.1-0.1-1.5-0.3c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.5-0.4-1-0.4-1.7s0.1-1.2,0.4-1.7s0.6-0.9,1.1-1.1
                    c0.5-0.3,0.9-0.4,1.5-0.4s1,0.1,1.4,0.3c0.3,0.2,0.6,0.5,0.8,0.8l-0.1,0.2l0.2-1.1h1.5v5.9h-1.6V21l0.2,0.4c0,0.1-0.1,0.1-0.2,0.3
                    c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4C1188.6,22.5,1188.3,22.6,1187.9,22.6z M1188.4,21.3c0.3,0,0.5,0,0.8-0.1
                    c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2,0.3-0.4,0.3-0.7v-1.3c-0.1-0.2-0.2-0.5-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.4s-0.5-0.2-0.8-0.2
                    s-0.6,0.1-0.9,0.2s-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1s0.1,0.7,0.3,1c0.2,0.3,0.4,0.5,0.7,0.7
                    C1187.7,21.2,1188,21.3,1188.4,21.3z"/>
                <path d="M1198.7,21.9c-0.3,0.2-0.6,0.4-1,0.5s-0.8,0.2-1.1,0.2c-0.7,0-1.2-0.1-1.7-0.4s-0.9-0.6-1.2-1.1s-0.4-1-0.4-1.7
                    c0-0.6,0.1-1.2,0.4-1.7s0.7-0.8,1.2-1.1c0.5-0.3,1-0.4,1.5-0.4s0.9,0.1,1.3,0.2s0.7,0.3,1,0.6l-0.8,1c-0.2-0.1-0.4-0.2-0.6-0.3
                    c-0.2-0.1-0.5-0.2-0.8-0.2s-0.6,0.1-0.8,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,0.9s0.1,0.6,0.2,0.9
                    c0.2,0.3,0.4,0.5,0.7,0.7s0.6,0.2,1,0.2c0.2,0,0.5,0,0.7-0.1s0.4-0.2,0.5-0.3L1198.7,21.9z"/>
                <path d="M1200.3,22.4V11.5h1.6v10.9H1200.3z M1201.4,20.6l-0.2-1.5l3.6-2.8l1,0.8L1201.4,20.6z M1203.4,18.4l2.7,4h-1.8l-1.9-3.1
                    L1203.4,18.4z"/>
                <path d="M1212.6,13.9h1.6v2.6h1.5v1.2h-1.5v4.7h-1.6v-4.7h-1v-1.2h1V13.9z"/>
                <path d="M1216.8,19.4c0-0.6,0.1-1.1,0.4-1.6s0.7-0.9,1.2-1.1c0.5-0.3,1.1-0.4,1.7-0.4s1.2,0.1,1.7,0.4s0.9,0.7,1.1,1.1
                    c0.3,0.5,0.4,1,0.4,1.6s-0.1,1.2-0.4,1.6c-0.3,0.5-0.7,0.9-1.1,1.1c-0.5,0.3-1.1,0.4-1.8,0.4c-0.6,0-1.2-0.1-1.7-0.4
                    s-0.9-0.6-1.2-1.1C1216.9,20.6,1216.8,20.1,1216.8,19.4z M1218.4,19.4c0,0.4,0.1,0.7,0.2,1s0.4,0.5,0.6,0.7
                    c0.3,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1s-0.1-0.7-0.2-1s-0.4-0.5-0.6-0.7
                    c-0.3-0.2-0.6-0.3-0.9-0.3s-0.6,0.1-0.9,0.3c-0.3,0.2-0.5,0.4-0.6,0.7S1218.4,19.1,1218.4,19.4z"/>
                <path d="M1230.1,13.9h1.6v2.6h1.5v1.2h-1.5v4.7h-1.6v-4.7h-1v-1.2h1V13.9z"/>
                <path d="M1234.3,19.4c0-0.6,0.1-1.1,0.4-1.6s0.7-0.9,1.2-1.1c0.5-0.3,1.1-0.4,1.7-0.4s1.2,0.1,1.7,0.4s0.9,0.7,1.1,1.1
                    c0.3,0.5,0.4,1,0.4,1.6s-0.1,1.2-0.4,1.6c-0.3,0.5-0.7,0.9-1.1,1.1c-0.5,0.3-1.1,0.4-1.8,0.4c-0.6,0-1.2-0.1-1.7-0.4
                    s-0.9-0.6-1.2-1.1C1234.4,20.6,1234.3,20.1,1234.3,19.4z M1235.8,19.4c0,0.4,0.1,0.7,0.2,1s0.4,0.5,0.6,0.7
                    c0.3,0.2,0.5,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1s-0.1-0.7-0.2-1s-0.4-0.5-0.6-0.7
                    c-0.3-0.2-0.6-0.3-0.9-0.3s-0.6,0.1-0.9,0.3c-0.3,0.2-0.5,0.4-0.6,0.7S1235.8,19.1,1235.8,19.4z"/>
                <path d="M1246.1,22.6c-0.4,0-0.8-0.1-1.2-0.3s-0.7-0.4-0.9-0.7l0.1-0.5v4.2h-1.6v-8.9h1.3l0.2,1.4l-0.2-0.4
                    c0.3-0.3,0.7-0.6,1.1-0.8c0.4-0.2,0.9-0.3,1.4-0.3c0.6,0,1.1,0.1,1.5,0.4s0.8,0.6,1,1.1s0.4,1,0.4,1.7s-0.1,1.2-0.4,1.7
                    s-0.7,0.8-1.1,1.1S1246.7,22.6,1246.1,22.6z M1245.8,21.3c0.4,0,0.7-0.1,1-0.2s0.5-0.4,0.7-0.7c0.2-0.3,0.3-0.6,0.3-1
                    s-0.1-0.7-0.2-1s-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.2-0.9-0.2s-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.7
                    V20c0.1,0.3,0.2,0.5,0.3,0.7c0.2,0.2,0.4,0.4,0.6,0.5C1245.3,21.3,1245.5,21.3,1245.8,21.3z"/>
            </g>
        </g>
    </svg>

    <img :class="[mobileView ? 'footer-m' : 'footer']" src="@/assets/footer-m.svg" @click="backToTop" v-else/>
  </div>
</template>

<script>
export default {
    name: "contact-me",
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
      },

    data () {
        return {
            mobileView: null,
            windowWidth: null,
        }
    },

    methods : {
        checkScreen () {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 750){
                this.mobileView= true;
                return;
            }
            this.mobileView = false;
        },
        
        backToTop () {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }
}
</script>

<style scoped>
.contact-me {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    justify-content: center;
}

.contact-me-m {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    justify-content: center;
}

.footer {
    position: absolue;
    width: 100%;
    height: auto;
    margin-top: 0px;
    bottom: 0;
    cursor: pointer;
}

.footer-m {
    width: 100%;
    height: auto;
    margin-top: 48px;
    }

.cm-text {
    font-family: "Futura-Med";
    font-size: 24px;
    margin-top: 12px;
}

.cm-text-m {
    font-family: "Futura-Med";
    font-size: 18px;
    margin-top: 12px;
}

.button-li {
    margin-top: 64px;
}

.button-li-m {
    justify-content: center;
    align-items: center;
    margin-top: 48px;
}

.contact-btn-1 {
    width: 80px;
    cursor: pointer;
    top: 0px;
    transition: 0.3s ease-in-out all ;
}

.contact-btn-1:hover {
    position: relative;
    transform: translateY(-10px);
}

.contact-btn {
    width: 80px;
    margin-left: 60px;
    cursor: pointer;
    transition: 0.3s ease-in-out all ;
}

.contact-btn:hover {
    position: relative;
    transform: translateY(-10px);
}

.btn-m {
    width: 64px;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
}

html {
    scroll-behavior: smooth;
}

#hand {
    animation: hand-move ease-in-out 1.2s alternate infinite;
}

@keyframes hand-move {
  from {
    transform: translateY(0px)
  }
  to {
    transform: translateY(-8px)
  }
}

.graphic {
    cursor: pointer;
}

.st0{fill:#EDB498;}
            .st1{fill:#794D2F;}
            .st2{fill:#503A2E;}
            .st3{fill:#F2BF98;}
            .st4{fill:#DEA881;}
            .st5{fill:#2F2A1B;}
            .st6{fill:#A3AD80;}
            .st7{fill:#868F69;}
            .st8{fill:#D8766F;}
            .st9{fill:#E9D8D3;}
</style>